import Container from "../../components/Container";
import {
  Flex,
  Box,
  Spacer,
  Button,
  Text,
  Heading,
  Input,
  keyframes,
  useToast,
  Center,
} from "@chakra-ui/react";
import {
  AppStoreSvg,
  LandingPageSvg,
  LogoSvg,
  PlayStoreSvg,
} from "../../assets/svg/svg";
//@ts-ignore
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

type Props = {};

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.005);
  }
  100% {
    transform: scale(1);
  }
`;

const Landing = (props: Props) => {
  let toast = useToast();
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh(); // Call refresh to apply animations to newly added elements
  }, []);

  const emailRef = useRef<any>("");

  const [loading, setLoading] = useState<boolean>(false);

  const JOIN_LIST = async () => {
    try {
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailRef.current.value)) {
        // console.log(emailRef.current.value);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");

        var formdata = new FormData();
        formdata.append("api_key", "OionzbZTmsAvBYwUSDuJMdNFRqXLCx");
        formdata.append("list", "RrtEONMd4QWdYeOvMJjg892Q");
        formdata.append("email", `${emailRef.current.value}`);
        formdata.append("boolean", "true");

        var requestOptions = {
          method: "POST",
          // headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };
        setLoading(true);

        //@ts-ignore
        fetch("https://mail.tribearc.com/subscribe", requestOptions)
          .then((response) => response.text())
          .then((result: any) => {
            console.log(JSON.stringify(result.status), "OPoop");
            if (result === "Already subscribed.") {
              toast({
                position: "top",
                status: "success",
                duration: 5000,
                isClosable: true,
                render: () => (
                  <Center color="white" p={3} bg="red" borderRadius={"10px"}>
                    You have already subscribed the waitlist
                  </Center>
                ),
              });
              emailRef.current.value = "";
              setLoading(false);
              return;
            }

            if (result) {
              toast({
                position: "top",
                status: "success",
                duration: 13000,
                isClosable: true,
                title: "Successful",
                description: "You have successfully joined the Litro Waitlist",
              });
              emailRef.current.value = "";
              setLoading(false);
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log("error", error);
          });
      } else {
        toast({
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
          render: () => (
            <Center color="white" p={3} bg="red" borderRadius={"10px"}>
              Please enter a valid email address.
            </Center>
          ),
        });
      }
    } catch (error) {}
  };

  return (
    <Box>
      <Container>
        {/* ----------------------------------------------HEADER--------------------------------------------------- */}
        <Flex py="20px">
          <Box as="button">
            <LogoSvg />
          </Box>
          <Spacer />
          {/* <Button
            fontSize={"10px"}
            w="160px"
            fontFamily={"GTWalsheimPro-Regular"}
          >
            Join Waitlist
          </Button> */}
        </Flex>

        {/* ----------------------------------------------BODY--------------------------------------------------- */}

        <Flex
          w="100%"
          flexDir={["column", "column", "row", "row", "row"]}
          // bg="red"
        >
          <Flex
            w={["100%", "100%", "46%", "46%", "46%"]}
            flexDir={"column"}
            data-aos="fade-up"
          >
            <Heading
              fontSize={["30px", "40px", "40px", "40px", "50px"]}
              mt={["20px", "20px", "20px", "60px", "70px"]}
              color={"white"}
              fontFamily={"GTWalsheimPro-Bold"}
              textAlign={["center", "center", "left", "left", "left"]}
              data-aos="zoom-in"
            >
              Credit, Remittance & Spend Management for Immigrants
            </Heading>

            <Text
              fontSize={["13px", "18px", "18px", "18px", "18px"]}
              mt="20px"
              color={"white"}
              fontFamily={"GTWalsheimPro-Regular"}
              textAlign={["center", "center", "left", "left", "left"]}
            >
              Don’t wait for payday. Get the funds you need for you and your
              loved ones back home (and monitor how they spend it). All at
              amazing rates from Litro, coming soon to users in US, UK, Canada,
              Kenya, Nigeria & Ghana!
            </Text>
            <Flex w="100%" h="50px" bg="white" mt="15px" borderRadius={"5px"}>
              <Input
                ref={emailRef}
                variant="unstyled"
                placeholder="Enter your Email Address"
                pl="15px"
                w="60%"
                fontSize={["18px", "18px", "18px", "18px", "18px"]}
                fontFamily={"GTWalsheimPro-Regular"}
              />
              <Spacer />

              <Button
                bg="#325CAB"
                fontSize={"10px"}
                w="160px"
                alignSelf={"center"}
                mr={"5px"}
                color={"white"}
                _hover={{
                  bg: "#325CAB",
                }}
                isLoading={loading}
                onClick={JOIN_LIST}
              >
                Join Waitlist
              </Button>
            </Flex>
            <Flex
              mt={["20px", "30px", "20px", "20px", "20px"]}
              mb={["10px", "10px", "10px", "20px", "20px"]}
              w="100%"
              // bg="cyan"
              justifyContent={[
                "center",
                "center",
                "flex-start",
                "flex-start",
                "flex-start",
              ]}
            >
              <Box as="button" mr="10px">
                <AppStoreSvg />
              </Box>

              <Box as="button">
                <PlayStoreSvg />
              </Box>
            </Flex>
          </Flex>
          <Spacer />
          <Flex
            w={["100%", "100%", "48%", "48%", "48%"]}
            justifyContent={"center"}
            animation={`${pulse} 2s infinite`}
          >
            <LandingPageSvg />
          </Flex>
        </Flex>
      </Container>

      {/* ----------------------------------------------FOOTER--------------------------------------------------- */}
      <Flex bg="#FFFFFF">
        <Container align="center" justify="center">
          <Flex
            flexDir={["column", "column", "row", "row", "row"]}
            display="flex"
            align="center"
            justify="center"
            fontSize={["10px", "14px", "16px", "18px", "20px"]}
          >
            <Text
              justifySelf="center"
              pt={{ base: "10px", md: "30px" }}
              fontFamily={"GTWalsheimPro-Regular"}
            >
              © Copyright 2023 | Litrogen, Inc. - All Rights Reserved
            </Text>
            <Text
              justifySelf="center"
              pt={{ base: "10px", md: "30px" }}
              fontFamily={"GTWalsheimPro-Regular"}
              ml={["5px", "10px", "20px", "30px", "40px"]}
            >
              +1-347-952-2041
            </Text>
            <Text
              justifySelf="center"
              pt={{ base: "10px", md: "30px" }}
              fontFamily={"GTWalsheimPro-Regular"}
              ml="20px"
              // data-aos="fade-up"
            >
              Engage@litrogen.com
            </Text>
          </Flex>
        </Container>
      </Flex>
    </Box>
  );
};

export default Landing;
