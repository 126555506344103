import { ChakraProvider, Box } from "@chakra-ui/react";
import litrogenTheme from "./constants/theme";
import Landing from "./pages/Landing/Landing";
import Fonts from "./assets/Fonts";
import { css, Global } from "@emotion/react";

export const App = () => {
  const GlobalStyles = css`
    /*
      This will hide the focus indicator if the element receives focus    via the mouse,
      but it will still show up on keyboard focus.
    */

    .js-focus-visible :focus:not([data-focus-visible-added]) {
      outline: none;
      box-shadow: none;
    }
  `;
  return (
    <ChakraProvider theme={litrogenTheme}>
      <Fonts />
      {/* <Global styles={GlobalStyles} /> */}

      <Box bg="#325CAB" h="100%">
        <Landing />
      </Box>
    </ChakraProvider>
  );
};
